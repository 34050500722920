

export const defaultBodyClasses = {
    // Default colors
    "& .default-blue": {
        background: '#b3dbff',
        fill: '#b3dbff'
    },
    "& .default-yellow": {
        background: '#ffff99',
        fill: '#ffff99'
    },
    "& .default-green": {
        background: '#aeeaae',
        fill: '#aeeaae'
    },
    "& .default-red": {
        background: '#ff6d6d',
        fill: '#ff6d6d'
    },
    "& .default-orange": {
        background: '#f2cc8f',
        fill: '#f2cc8f'
    },
    "& .default-grey": {
        background: '#ccc',
        fill: '#ccc'
    },

    "& .default-red-text": {
        color: '#ff6d6d'
    },

    "& .fuel-card": {
        "& .green": {
            background: 'rgb(123, 199, 130)',
            color: 'black',
        },
        "& .yellow": {
            background: 'rgb(212, 224, 130)',
            color: 'black'
        },
        "& .orange": {
            background: 'rgb(231, 132, 92)',
            color: 'black'
        },
        "& .red": {
            background: 'rgb(233, 117, 111)',
            color: 'black'
        }
    },
    "& .service-normal": {
        backgroundColor: 'inherit',
        "&:hover": { backgroundColor: '#0001' },
        "&.Mui-hovered": { backgroundColor: '#0001' },
        "&.MuiDataGrid-row:hover": { backgroundColor: '#0001' },
    },
    "& .service-history": {
        "& .autofutur-normal": {
            backgroundColor: 'inherit',
            color: 'black',
        },
        "& .autofutur-sent": {
            backgroundColor: '#D3d3d3',
            "&:hover": { backgroundColor: '#a5adcc' },
            "&.Mui-hovered": { backgroundColor: '#a5adcc' },
            color: 'black',

        },
        "& .autofutur-resend": {
            backgroundColor: '#ffffed',
            "&:hover": { backgroundColor: '#F0E2B6' },
            "&.Mui-hovered": { backgroundColor: '#F0E2B6' },
            color: 'black',
        },
        "& .service-edit": {
            color: 'black',
            backgroundColor: '#e8faff',
        },
    },
    "& .report-grid": {
        "& .report-totals": {
            fontWeight: "bold",
        },
        "& .report-avgs": {
            fontWeight: "bold",
        },
    },
    "& .row-default-blue": {
        background: '#b3dbff',
        fill: '#b3dbff',
        "&.MuiDataGrid-row": {
            "&:hover": { background:'#80c3ff' },
            "&.Mui-hovered": { background: '#80c3ff' },
            "&.Mui-selected": {
                background: '#80c3ff',
                "&:hover": { background: '#66b8ff' },
                "&.Mui-hovered": { background: '#66b8ff' },
            },
        },
    },
    "& .row-default-yellow": {
        background: '#ffff99',
        fill: '#ffff99',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#f4f471' },
            "&.Mui-hovered": { background: '#f4f471' },
            "&.Mui-selected": {
                background: '#f4f471',
                "&:hover": { background: '#eded5e' },
                "&.Mui-hovered": { background: '#eded5e' },
            },
        },
    },
    "& .row-default-green": {
        background: '#a8f0a8',
        fill: '#a8f0a8',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#88dd88' },
            "&.Mui-hovered": { background: '#88dd88' },
            "&.Mui-selected": {
                background: '#88dd88',
                "&:hover": { background: '#79d279' },
                "&.Mui-hovered": { background: '#79d279' },
            },
        },
    },
    "& .row-default-red": {
        background: '#ff9999',
        fill: '#ff9999',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#fc8282' },
            "&.Mui-hovered": { background: '#fc8282' },
            "&.Mui-selected": {
                background: '#fc8282',
                "&:hover": { background: '#f76e6e' },
                "&.Mui-hovered": { background: '#f76e6e' },
            },
        },
    },
    "& .row-default-orange": {
        background: '#efc78f',
        fill: '#efc78f',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#f0bd75' },
            "&.Mui-hovered": { background: '#f0bd75' },
            "&.Mui-selected": {
                background: '#f0bd75',
                "&:hover": { background: '#edb25e' },
                "&.Mui-hovered": { background: '#edb25e' },
            },
        },
    },
    "& .row-default-grey": {
        background: '#ccc',
        fill: '#ccc',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#bbb' },
            "&.Mui-hovered": { background: '#bbb' },
            "&.Mui-selected": {
                background: '#bbb',
                "&:hover": { background: '#aaa' },
                "&.Mui-hovered": { background: '#aaa' },
            },
        },
    },
    "& .MuiDataGrid-root": {
        "& .MuiDataGrid-row.Mui-selected": {
            color: "black",
        },
    },
    '& .MuiDataGrid-footerContainer': {
        height: 32,
        minHeight: 32
    }
}

export const defaultBodyClassesDark = {
    ...defaultBodyClasses,
    // Default colors
    "& .default-blue": {
        background: '#244e75',
        fill: '#244e75'
    },
    "& .default-yellow": {
        background: '#83833f',
        fill: '#83833f'
    },
    "& .default-green": {
        background: '#2e662e',
        fill: '#2e662e'
    },
    "& .default-red": {
        background: '#973131',
        fill: '#973131'
    },
    "& .default-orange": {
        background: '#8f631e',
        fill: '#8f631e'
    },
    "& .default-grey": {
        background: '#414141',
        fill: '#414141'
    },

    "& .default-red-text": {
        color: '#e65050'
    },

    "& .fuel-card": {
        "& .green": {
            background: 'rgb(54, 131, 61)',
            color: 'white'
        },
        "& .yellow": {
            background: 'rgb(142, 153, 68)',
            color: 'white'
        },
        "& .orange": {
            background: 'rgb(159, 85, 55)',
            color: 'white'
        },
        "& .red": {
            background: 'rgb(169, 77, 72)',
            color: 'white'
        }
    },
    "& .service-normal": {
        backgroundColor: 'inherit',
        "&:hover": { backgroundColor: '#FFF1' },
        "&.Mui-hovered": { backgroundColor: '#FFF1' },
        "&.MuiDataGrid-row:hover": { backgroundColor: '#FFF1' },
    },
    "& .service-history": {
        "& .autofutur-normal": {
            backgroundColor: 'inherit',
            "&:hover": { backgroundColor: 'inherit', filter: "brightness(125%)" },
            "&.Mui-hovered": { backgroundColor: 'inherit', filter: "brightness(125%)" },
        },
        "& .autofutur-sent": {
            backgroundColor: '#333333',
            "&:hover": { backgroundColor: '#393c4d' },
            "&.Mui-hovered": { backgroundColor: '#393c4d' },
            "&.Mui-selected": { backgroundColor: '#393c4d' },
            color: 'white',
        },
        "& .autofutur-resend": {
            backgroundColor: '#4d4d41',
            "&:hover": { backgroundColor: '#666247' },
            "&.Mui-hovered": { backgroundColor: '#666247' },
            "&.Mui-selected": { backgroundColor: '#666247' },
            color: 'white'
        },
        "& .service-edit": {
            color: 'white',
            backgroundColor: 'grey',
        }
    },
    "& .report-grid": {
        "& .report-totals": {
            fontWeight: "bold",
        },
        "& .report-avgs": {
            fontWeight: "bold",
        },
    },
    "& .row-default-blue": {
        background: '#244e75',
        fill: '#244e75',
        "&.MuiDataGrid-row": {
            "&:hover": { background:'#2968a3' },
            "&.Mui-hovered": { background: '#2968a3' },
            "&.Mui-selected": {
                background: '#2968a3',
                "&:hover": { background: '#2e75b8' },
                "&.Mui-hovered": { background: '#2e75b8' },
            },
        },
    },
    "& .row-default-yellow": {
        background: '#636336',
        fill: '#636336',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#85853d' },
            "&.Mui-hovered": { background: '#85853d' },
            "&.Mui-selected": {
                background: '#85853d',
                "&:hover": { background: '#8f8f3d' },
                "&.Mui-hovered": { background: '#8f8f3d' },
            },
        },
    },
    "& .row-default-green": {
        background: '#2e662e',
        fill: '#2e662e',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#318131' },
            "&.Mui-hovered": { background: '#318131' },
            "&.Mui-selected": {
                background: '#318131',
                "&:hover": { background: '#328f32' },
                "&.Mui-hovered": { background: '#328f32' },
            },
        },
    },
    "& .row-default-red": {
        background: '#813131',
        fill: '#813131',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#a63f3f' },
            "&.Mui-hovered": { background: '#a63f3f' },
            "&.Mui-selected": {
                background: '#a63f3f',
                "&:hover": { background: '#b54040' },
                "&.Mui-hovered": { background: '#b54040' },
            },
        },
    },
    "& .row-default-orange": {
        background: '#6c4713',
        fill: '#6c4713',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#865613' },
            "&.Mui-hovered": { background: '#865613' },
            "&.Mui-selected": {
                background: '#865613',
                "&:hover": { background: '#965f13' },
                "&.Mui-hovered": { background: '#965f13' },
            },
        },
    },
    "& .row-default-grey": {
        background: '#444',
        fill: '#444',
        "&.MuiDataGrid-row": {
            "&:hover": { background: '#555' },
            "&.Mui-hovered": { background: '#555' },
            "&.Mui-selected": {
                background: '#555',
                "&:hover": { background: '#6a6a6a' },
                "&.Mui-hovered": { background: '#606060' },
            },
        },
    },
    "& .MuiDataGrid-root": {
        "& .MuiDataGrid-row.Mui-selected": {
            color: "white",
        },
    }
}