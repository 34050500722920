import PopupScreen from "./PopupScreen";
import { Box, FormGroup, FormControl, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Select, InputLabel } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";
import { useEffect, useState } from "react";
import LogiTarUser from "../misc/User";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import LogitarApi from "../api/LogitarApi";
import NewServiceOrder from "../components/NewServiceOrder";
import AlertPop from "./AlertPop";

/**
 * @typedef {{
 *  row: import("../api/LogitarApiTypes").GetServiceMonitorRow,
 *  onSave: (orderInfo: {dateTime: Date, place: string, details: string}) => void
 *  onClose: () => void
 * }} ServiceMonitorOrderPop
 */

/**
 * 
 * @param {ServiceMonitorOrderPop} props 
 * @returns 
 */
export default function ServiceMonitorOrderPop (props) {

    const [orderInfo, setOrderInfo] = useState({
        dateTime: null,
        place: "",
        details: "",
        orderer: LogiTarUser.current.info.id
    });
    const [savingError, setSavingError] = useState(false);

    const[places, setPlaces] = useState([]);
    const[selectedPlace, setSelectedPlace] = useState(null);

    //--> for NewServiceOrder
    const [popupNew, setPopupNew] = useState(false);
    const [serviceOrderForEdit, setServiceOrderForEdit] = useState();
    const [searchTimeStamp, setSearchTimeStamp] = useState("");  //Page refresh don't need. Is for NewServiceOrder integration purpose
    const [vehicles, setVehicles] = useState([]);
    //<--

    const [saveMode, setSaveMode] = useState(null);
    const [showClosePopup, setShowClosePopup] = useState(false);

    // Get external service companies
    useEffect(() => {
        const GetExternalServices = async () => { 
            try {
                const result = await LogitarApi.getExternalServices({ id: null });
                if (result && result.externalservice) {
                    let placestable = [];
                    result.externalservice.forEach((value) => {
                        let place = {
                            name: value.companyName,
                            listname: value.companyName,
                            isExternal: 1,
                            connection: null
                        };
                        placestable.push(place);
                    });
                    setPlaces((prevPlaces) => [...prevPlaces, ...placestable]);
                } else {
                    console.error("No external services found or result structure is unexpected.");
                }
            } catch (error) {
                console.error("Failed to fetch external services:", error);
            }
        };       

        GetExternalServices();
    }, []);

    // Get own service companies
    useEffect(() => {
        const GetOwnServices = async () => { 
            try {
                const title = "ServiceHistory";
                const subtitle = "AutofuturOffice";
                const result = await LogitarApi.getControlValues({ title: title, subtitle: subtitle });
                if (result && result.controlvalues) {
                    let placestable = [];
                    result.controlvalues.forEach((value) => {
                        let place = {
                            name: value.name,
                            listname: value.name +  " (oma toimipaikka)",
                            isExternal: 0,
                            connection: value.value
                        };
                        placestable.push(place);
                    });
                    setPlaces((prevPlaces) => [...prevPlaces, ...placestable]);
                } else {
                    console.error("No control values found or result structure is unexpected.");
                }
            } catch (error) {
                console.error("Failed to fetch external services:", error); 
            }
        };       
    
        GetOwnServices();
    }, []);

    //Get vehicle and trailer
    useEffect(() => {
        const vehicleid = props.row.vehicleId
        LogitarApi.getVehiclelist({method: "vehiclelist", vehicleid: vehicleid})
        .then((result) => {
            setVehicles(result.vehicles);  
            console.log("Fetched vehicles", result.vehicles)
        }) 
        .catch((err) => {});
    }, []);
      
    const onSaveOrder = () => {
        // if(!orderInfo.dateTime) {
        //     enqueueSnackbar("Täytä päivämäärä ja aika!", { variant: "error" });
        //     setSavingError(true);
        //     return;
        // }
        // alert("paikka", orderInfo.place)
        // if (!orderInfo.place) {
        //     enqueueSnackbar("Täytä paikkatieto!", { variant: "error" });
        //     setSavingError(true);
        //     return;
        // }

        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", { variant: "error" })
            return;
        }

        const oi = {...orderInfo};

        oi.dateTime = format(oi.dateTime, "yyyy-MM-dd HH:mm:ss");

        props.onSave({...props.row, orderInfo: oi});
    }

    const handleChangePlace = (e) => {
        setOrderInfo({...orderInfo, place: e.target.value.name})
        setSelectedPlace(e.target.value)
        if (e.target.value.isExternal == 0) {
            setPopupNew(true);
        }
        else {
            setPopupNew(false);
        }

        setServiceOrderForEdit({
            vehicle: props.row.vehicleId,
            driver: "",
            driverByName: "",
            orderDate: orderInfo.dateTime,
            servicedByExt: "",
            ExternalService_id: "",
            beforeDate: "",
            beforeKm: "",
            alarm: "",
            priority: "",
            connection: e.target.value.connection,
            orderInfoText: (props.row.type + "\n" + orderInfo.details),
            vehicleByNumber: props.row.vehicle 
          });
    }
    
    const setNewSearchTimeStamp = () => {
        setSearchTimeStamp(Date.now());

    };

    const checkIsEmpty = (value) => {
        if (saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
          return true;
        }
        return false; 
    };

    const checkSaveIsValid = () => {
        if (orderInfo.dateTime == null || orderInfo.dateTime.length < 1 ) return false;
        if (orderInfo.place == null || orderInfo.place.length < 1 ) return false;
        if (orderInfo.details == null || orderInfo.details.length < 1 ) return false;
        return true;
    }

    const handleClose = () => {
        setShowClosePopup(true)
    };

    return <PopupScreen
        title="Tilaa huolto"
        staticButtons
        draggable
        // onClose={props.onClose}
        onClose={handleClose}   
        onSave={() => onSaveOrder()}
        // saveProps={{ disabled: selectedPlace && selectedPlace.isExternal == 0 }}
    >
        <Box
            sx={{
                ".MuiTextField-root": {
                    m: 0.5,
                    flex: 1
                }
            }}
        >
            <FormGroup row>
                <Table sx={{m: 0.5}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Kalusto</TableCell>
                            <TableCell>Tyyppi</TableCell>
                            <TableCell>Tilaaja</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{props.row.vehicle}</TableCell>
                            <TableCell>{props.row.type}</TableCell>
                            <TableCell>{LogiTarUser.current.info.name}</TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </FormGroup>
            <FormGroup row>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
                    <DateTimePicker 
                        value={orderInfo.dateTime}
                        onChange={(v) => setOrderInfo({...orderInfo, dateTime: v})}
                        label="Huollon päivämäärä ja aika*"
                        sx={{m: 0.5}}
                        required={true}

                        slotProps={{
                            textField: {
                                InputProps: {
                                    required: true,
                                    error: checkIsEmpty(orderInfo.dateTime)
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
                {/* <TextField 
                    label="Paikka"
                    value={orderInfo.place}
                    onChange={(e) => setOrderInfo({...orderInfo, place: e.target.value})}
                    required
                    error={!orderInfo.place && savingError}
                /> */}

                <FormControl sx={{minWidth: 280, flex: 1}}>
                    <InputLabel id="service-external-repairman-label">Paikka*</InputLabel>
                    <Select
                        labelId="service-external-repairman-label"
                        label="Paikka*"
                        value={selectedPlace || ' '}
                        onChange={(e) => handleChangePlace(e)}
                        sx={{height: 55, m:0.5}}
                        error={checkIsEmpty(orderInfo.place)}
                        required={true}
                    >
                        <MenuItem value={0}>-</MenuItem> 
                        {[...places]
                            .sort((a, b) => b.isExternal - a.isExternal)
                            .map((value, key) => {
                                return (
                                    <MenuItem value={value} key={key}>{value.listname}</MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>

            </FormGroup>
            <FormGroup row>
                <TextField 
                    label="Pyydetty huolto*"
                    value={orderInfo.details}
                    multiline
                    rows={4}
                    onChange={(e) => setOrderInfo({...orderInfo, details: e.target.value})}
                    error={checkIsEmpty(orderInfo.details)}
                />
            </FormGroup>

            {popupNew  && (
                <div>
                    <NewServiceOrder
                        serviceHistory={serviceOrderForEdit}
                        vehicles={vehicles}
                        onClick={setPopupNew}
                        new={true}
                        rowUpdate={setNewSearchTimeStamp}
                        serviceMonitor = {'true'}
                    ></NewServiceOrder>
                </div>
            )}

            {
            showClosePopup &&
                <AlertPop
                    title="Haluatko varmasti sulkea?"
                    cancellable
                    onClose={(accepted) => {
                        if(accepted) {
                            props.onClose()
                        }
                        else
                            setShowClosePopup(false);
                    }}
                />
            }

        </Box>

    </PopupScreen>
}