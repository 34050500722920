
/**
 * LogitarApi class for handling api interaction, for easier and simpler usage
 */

import { format } from "date-fns";
import { LogiTarUserType } from "../misc/User";
import { getErrorLog, getErrorDescription } from "./Errors";
import { unstable_useId } from "@mui/material";
import { FuelType } from "../components/FuelPriceCard";

export let APIPath = window.location.hostname === "localhost" || 
    window.location.hostname.startsWith("192.168.") ? 
    `http://${window.location.hostname}:91` : 
    (
        window.location.hostname.includes("-test") ? 
                "https://api.tarkkala-testi.logitar.fi" : 
                "https://api.tarkkala.logitar.fi"
    );

//export let APIPath = "https://logitardev.softrain.fi/api/base"

export default class LogitarApi {

    /**
     * @brief Return cookie named by parameter
     * @param {String} name 
     * @returns {String|undefined} Returns cookie
     */
    static getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    /**
     * @brief Expire users login token, thus making user log out
     */

    static logout() {
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        window.location.reload()
    }

    /**
     * @brief Make fetch call to the endpoint and parse dataObject as parameters
     * @param {string} endpoint 
     * @param {object} dataObject 
     * @param {'get'|'post'|undefined} forceMethod
     * @returns {Promise<object>}
     */
    static request(endpoint, dataObject = {}, forceMethod = undefined) {

        let method = 'get';
        switch(forceMethod) {
            case 'get':
                method = 'get';
                break;
            case 'post':
                method = 'set';
                break;
            default:
                method = endpoint.match("\/(set|get).*(?=\.php)").pop()
                break;
        }

        let initOptions = {}

        let requestUrl = `${APIPath}${endpoint}`

        switch (method) {
            case ("set"):
                const formData = new FormData()

                formData.append("key", this.getCookie("login-token"))

                for (const attr of Object.keys(dataObject)) {
                    if (dataObject[attr]) {
                        formData.append(attr, dataObject[attr])
                    }
                }

                initOptions = { method: "POST", body: formData }

                break
            case ("get"):
            default:

                let urlParameters = `?key=${this.getCookie("login-token")}`

                //Attribute name must be same as in needed in request

                for (const attr of Object.keys(dataObject)) {
                    if (dataObject[attr]) {
                        urlParameters += `&${attr}=${dataObject[attr]}`
                    }
                }

                initOptions.method = "GET"

                requestUrl += urlParameters

                break
        }

        console.log("Endpoint-osoite: ")
        console.log(requestUrl)

        let newPromise = new Promise((resolve, reject) => {
            fetch(requestUrl, { ...initOptions })
                .then(response => response.json())
                .then(result => {
                    this.fetchFailed = false;
                    if (result.status) {
                        resolve(result)
                    } else {
                        console.log(`Error code: ${getErrorLog(result.error)}`)
                        //console.error(result)
                        reject(result)
                    }
                })
                .catch((err) => {
                    this.fetchFailed = true;
                    reject(err)
                })
        })

        return newPromise

    }

    /**
     * @brief True if the last fetch failed
     */
    static fetchFailed = false;

    /**
     * @brief Fetch file
     * @param {number} id file id
     * @returns {Promise<GetFileResponse>} file
     */
    static getFile(id) {

        const url = `/getfile.php`

        return this.request(url, { id: id })
    }

    /**
     * 
     * @param {number} id 
     */
    static downloadFile (id) {
        const url = `${APIPath}/getfile.php?key=${this.getCookie("login-token")}&id=${id}&mode=download`;
        window.location = url;
    }

    /**
     * @brief Send file to database to be saved or deleted
     * @param {SetFileRequest} file
     * @param {FileTableLink} tableName Which table to link the file
     * @returns {Promise<DefaultSetResponse>} Returns a Promise if file saved or removed
     */
    static setFile(file, tableName) {

        let params = { file: encodeURIComponent(JSON.stringify(file)), table: tableName }

        const url = `/setfile.php`

        return this.request(url, params)
    }

    /**
     * @brief Fetch items
     * @param {number} id
     * @param {'all'|'list'|'allfile'} extent
     * @param {string} search from id or name
     * @param {number} client get items by client id
     * @returns {Promise<GetItemsResponseAll|GetItemsResponseList|GetItemsResponseAllFile>} Returns a Promise with wanted items
     */
    static getItems({ id = null, extent = null, search = null, client = null } = {}) {

        const url = `/getitems.php`

        return this.request(url, { id: id, extent: extent, search: search, client: client })
    }

    /**
     * @brief Update wanted item
     * @param {LogitarItem} item 
     * @returns {Promise<DefaultSetResponse>}
     */
    static setItem(item) {

        const url = `/setitem.php`

        return this.request(url, { item: encodeURIComponent(JSON.stringify(item)) })
    }

    /**
     * @brief Fetch all clients
     * @param {number} id
     * @param {'all'|'list'|'alljoin'|'listjoin'} extent
     * @param {string} search from id or name
     * @returns {Promise} Returns a Promise with wanted clients
     */
    static getClients({ id = null, extent = null, search = null } = {}) {

        const url = `/getclients.php`

        return this.request(url, { id: id, extent: extent, search: search })
    }

    /**
     * @brief Update wanted client
     * @param {Object} client 
     * @returns {Promise} Returns a Promise if updated/created
     */
    static setClient(client) {

        const url = `/setclient.php`
        console.log("Asiakas " + JSON.stringify(client))

        return this.request(url, { client: encodeURIComponent(JSON.stringify(client)) })
    }

    /**
     * @brief Fetch all vehicles, or by search
     * @param {number} id
     * @param {String} extent "all", "list"
     * @param {String} search from id or name or licenseNumber
     * @returns {Promise} Returns a Promise with wanted vehicles
     */
    static getVehicles({ id = null, extent = null, search = null } = {}) {

        const url = `/getvehicles.php`

        return this.request(url, { id: id, extent: extent, search: search })
    }

    static getVehiclelist({ userid = null, method = null, vehicleid = null } = {}) {
        const url = `/getvehicles.php`
        return this.request(url, { userid: userid, method: method, vehicleid: vehicleid})
    }


    /**
     * @brief Update wanted vehicle
     * @param {Object} vehicle 
     * @returns {Promise} Returns a Promise if updated/created
     */
    static setVehicle(vehicle) {

        const url = `/setvehicle.php`

        return this.request(url, { vehicle: encodeURIComponent(JSON.stringify(vehicle)) })
    }

    /**
     * @brief Fetch all vehicles locations
     * @param {number?} vehicle 
     * @param {(Date | {start: Date, end: Date})?} date
     * @returns {Promise<import("./LogitarApiTypes").GetVehicleLocationsResponse>} Returns a Promise with wanted vehicle locations
     */
    static getVehicleLocations(vehicle, date) {

        const url = `/getvehiclelocations.php`
        let params = {}
        if(vehicle) {
            params.vehicle = vehicle
        }
        if(date) {
            if(date instanceof Date) {
                params.date = format(date, "yyyy-MM-dd")
            }
            else if(date.start && date.end) {
                params.start = format(date.start, "yyyy-MM-dd HH:mm:ss");
                params.end = format(date.end, "yyyy-MM-dd HH:mm:ss");
            }
        }
        return this.request(url, params)
    }

    /**
     * @brief Fetch all users matching the parameters
     * @param {{search?: string, userType?: LogiTarUserType, extent?: "all" | "list", id?: number}}
     * @returns {Promise} Returns a Promise with wanted users
     */
    static getUsers({ search = null, userType = null, extent = null, id = null } = {}) {

        const url = `/getusers.php`

        return this.request(url, { search: search, type: userType, extent: extent, id: id })
    }

    /**
     * @brief Update wanted user
     * @param {Object} user 
     * @returns {Promise} Returns a Promise if updated/created
     */
    static setUser(driver) {

        const url = `/setuser.php`
        console.log("user ", JSON.stringify(driver))

        return this.request(url, { user: encodeURIComponent(JSON.stringify(driver)) })
    }

    /**
     * @deprecated
     * @brief Fetch plans by date and shift
     * @param {String} date
     * @param {String} shift
     * @param {Int} vehicleID
     * @param {number} vehicleType
     * @returns {Promise} Returns a Promise with plans
     */
    static getPlans(date, shift, vehicleID = null, vehicleType = null) {

        let params = {}

        const url = `/getplans.php`

        params.date = date
        params.shift = shift

        if (vehicleID !== null &&    vehicleID !== undefined) {
            params.vehicleID = vehicleID
        }
        if(vehicleType !== null && vehicleType !== undefined) {
            params.vehicleType = vehicleType;
        }

        return this.request(url, params)
    }

    /**
     * @deprecated
     * @brief Fetch plans from date range
     * @param {String} startDate
     * @param {String} endDate
     * @param {Int} vehicleID
     * @param {number} vehicleType
     * @returns {Promise} Returns a Promise with plans
     */
    static getPlansRange(startDate, endDate, vehicleID = null, vehicleType = null) {

        let params = {}

        const url = `/getplans.php`

        params.extent = "timeframe";
        params.start = startDate;
        params.end = endDate;

        if (vehicleID !== null && vehicleID !== undefined) {
            params.vehicleID = vehicleID
        }
        if(vehicleType !== null && vehicleType !== undefined) {
            params.vehicleType = vehicleType;
        }

        return this.request(url, params)
    }

    /**
     * @deprecated
     * @brief Update or create plans
     * @param {Array | Object} plans
     * @param {Boolean | null} del
     * @returns {Promise}
     */
    static setPlans(plans, del = null) {

        let params = {}

        params.plans = encodeURIComponent(JSON.stringify(plans))

        if (del) {
            params.del = del
        }

        const url = `/setplans.php`

        return this.request(url, params)
    }

    /**
     * @brief Retrieve message chains
     * @returns {Promise} Message chains
     */
    static getChains(usertype) {

        const url = `/getchains.php`

        let params = {}
        if (usertype)
            params.userType = usertype;    

        return this.request(url,params)
    }

    /**
     * @brief Create or delete chain
     * @param {Object} chain 
     * @param {Boolean} del 
     * @returns {Promise}
     */

    static setChain(chain, del = null) {

        let params = { chain: encodeURIComponent(JSON.stringify(chain)) }

        if (del) {
            params.del = del
        }

        const url = `/setchain.php`

        return this.request(url, params)
    }

    static acceptChain(chain, userType = null) {

        let params = { chain: encodeURIComponent(JSON.stringify(chain)) }
        console.log("Viestiketju " + JSON.stringify(chain))
        
        let method="accept"
        params.method = method

        if (userType) {
            console.log("Viestiketjun hyväksyjä");
            console.log(userType)
            params.userType= userType
        }

        const url = `/setchain.php`
        return this.request(url, params)
    }


    /**
     * @brief Retrieve unread messages 
     * @param {Int} vehicleId 
     * @param {String} method
     * @returns Messages or count
     */
    static getUnreadMessages(vehicleId, method, usertype) {

        const url = `/getchains.php`
        
        const params = {
            method: method
        }
        if (usertype)
            params.userType = usertype;        
        if (vehicleId)
            params.vehicleId = vehicleId;

        return this.request(url, params)
    }

    /**
     * @brief Retrieve messages of a chain
     * @param {Int} chainId 
     * @returns Messages of a chain
     */
    static getChainMessages(chainId) {

        const url = `/getchainmessages.php`

        return this.request(url, { messageChain: chainId })
    }

    /**
     * @brief Send message to database
     * @param {Object} message 
     * @returns {Promise}
     */
    static setMessage(message) {

        const url = `/setmessage.php`

        return this.request(url, { message: encodeURIComponent(JSON.stringify(message)) })
    }

    /**
     * @brief Get all news
     * @param {number} id
     * @param {String} extent "all", "list", "allfile"
     * @param {String} search from id or header or date
     * @returns {Promise} Promise with news
     */
    static getNews({ id = null, extent = null, search = null, notification = null, user = null } = {}) {

        const url = `/getnews.php`

        console.log("User of noticication" + user)
        return this.request(url, { id: id, extent: extent, search: search, notification: notification, user: user })
    }

    /**
     * @brief Update, create or remove news
     * @param {Object} news 
     * @param {Boolean} del
     * @returns {Promise} If updated, created or removed
     */
    static setNews(news, del = null) {

        let params = {}

        params.news = encodeURIComponent(JSON.stringify(news))

        if (del) {
            params.del = del
        }

        const url = `/setnews.php`

        return this.request(url, params)
    }

    /**
     * @brief Get all training
     * @param {number} id
     * @param {string} extent "all", "list", "allfile"
     * @param {string} search from id or header or date
     * @returns {Promise<{status: true, training: TrainingRow[]}>} Promise with training
     */
    static getTraining({ id = null, extent = null, search = null } = {}) {

        const url = `/gettraining.php`

        return this.request(url, { id: id, extent: extent, search: search })
    }

    /**
     * @brief Update, create or remove Training
     * @param {TrainingRow} training 
     * @param {boolean} del
     * @returns {Promise} If updated, created or removed
     */
    static setTraining(training, del = null) {

        let params = {}

        params.training = encodeURIComponent(JSON.stringify(training))

        if (del) {
            params.del = del
        }

        const url = `/settraining.php`

        return this.request(url, params)
    }

    /**
     * @brief Get types of cargo 'cargoType'
     * @param {String} search 
     * @param {number} id
     * @param {number} client client id
     * @returns {Promise}
     */
    static getCargoTypes({ id = null, search = null, client = null } = {}) {

        const url = `/getcargotypes.php`

        return this.request(url, { id: id, search: search, client: client })
    }

    /**
     * @brief Create, update or delete cargotype
     * @param {Object} cargos
     * @returns {Promise}
     */
    static setCargoType(cargos) {

        const url = `/setcargotype.php`

        console.log(JSON.stringify(cargos))

        return this.request(url, { cargos: encodeURIComponent(JSON.stringify(cargos)) })
    }

    /**
     * @brief Gets job data for planning views
     * @param {Date} date 
     * @param {'A'|'I'} shift 
     * @param {number} id Vehicle ID
     * @returns {Promise<import("./LogitarApiTypes").GetPlanningResponse>}
     */
    static getPlanning (date, shift, id = null) {
        const url = `/getplanning.php`;

        const params = {
            date: format(date, "yyyy-MM-dd"),
            shift: shift,
            vehicleID: id
        };

        return this.request(url, params);
    }

    /**
     * @brief Gets job data for planning views for range
     * @param {Date} start 
     * @param {Date} end
     * @param {number?} vehicle 
     * @returns {Promise<GetPlanningResponse>}
     */
    static getPlanningRange (start, end, vehicle = undefined) {
        const url = `/getplanning.php`;

        const params = {
            start: format(start, "yyyy-MM-dd"),
            end: format(end, "yyyy-MM-dd"),
            vehicleID: vehicle
        };

        return this.request(url, params);
    }

    /**
     * @brief Fetch jobs
     * @param {object} timeFrame {start, end}
     * @returns {Promise} Returns a Promise with jobs
     */
    static getJobs({ timeFrame = null, extent = null, users = null, dateCompare = null } = {}) {

        const url = `/getjobs.php`

        let start, end = null

        if (timeFrame) {
            start =  format(timeFrame.start, "yyyy-MM-dd")
            end = format(timeFrame.end, "yyyy-MM-dd")
        }

        if(!dateCompare || dateCompare !== 'date' && dateCompare !== 'billingDate') {
            dateCompare = undefined;
        }

        return this.request(url, { start: start, end: end, extent: extent, dateCompare: dateCompare, users: JSON.stringify(users) })
    }

    /**
     * @brief Update or create jobs
     * @param {Array | Object} jobs
     * @param {Object} commitInfo (date,shift,vehicle)
     * @returns {Promise}
     */
    static setJobs(jobs = null, commitInfo = undefined) {

        const params = {};
        
        console.log(commitInfo)
        if (jobs) {
            params.jobs = encodeURIComponent(JSON.stringify(jobs))
        } else if (commitInfo) {
            params.commitInfo =  encodeURIComponent(JSON.stringify(commitInfo))
        }

        const url = `/setjobs.php`
        return this.request(url, params);
    }

    /**
     * @brief Update or create jobsplit
     * @param {Object} jobsplit 
     * @param {Boolean | null} del
     * @returns 
     */
    static setJobSplit(jobsplit, del = null) {

        const url = "/setjobsplit.php"
        return this.request(url, {jobsplit: encodeURIComponent(JSON.stringify(jobsplit)), del: del})
    }

    /**
     * @brief Fetch logs
     * @returns {Promise} Returns a Promise with plans
     */
    static getLogs() {

        const url = `/getlogs.php`

        return this.request(url)
    }

    /**
    * @brief Get api documentation as json
    * @returns {Promise} Returns a Promise with api doc 
    */
    static getApiDoc() {

        const url = `/getapidoc.php`

        return this.request(url)
    }

    /**
     * @brief Fetch all orders
     * @param {{id?: number, extent?:"all"|"list",search?:string,date?:string, combined?: boolean}} extent
     * @returns {Promise} Returns Promise with order(s)
     */
    static getOrders({ id = null, extent = null, search = null, date = null, combined = undefined } = {}) {

        const url = `/getorders.php`

        return this.request(url, { id: id, extent: extent, search: search, date: date, combined: combined ? 1 : 0 })
    }

    /**
     * @brief Gets orders for a week
     * @param {string} date 
     * @returns {Promise<GetWeekOrdersResponse>}
     */
    static getWeekOrders (date) {
        const url = `/getweekorders.php`;

        return this.request(url, {date: date});
    }

    /**
     * @brief Set order
     * @param {String} order 
     * @returns 
     */
    static setOrder(order, del = null) {

        let url = "/setorder.php"

        return this.request(url, { order: encodeURIComponent(JSON.stringify(order)), del: del })
    }

    /**
     * @brief Fetch workhours
     * @param {Date?} from 
     * @param {Date?} to 
     * @param {number?} user
     * @param {number?} id
     * @param {Date?} date
     * @param {'A' | 'I'} shift
     * @returns 
    */
    static getWorkHours(from = null, to = null, user = null, id = null, date = null, shift = null) {

        const url = "/getworkhours.php"
        let params = {}

        if (id) {
            params.id = id
        } else {
            if (user) {
                params.user = user
            }
            if (from && to) {
                params = {...params, from: format(from, "yyyy-MM-dd"), to: format(to, "yyyy-MM-dd")}
            } else if (date && shift) {
                params = {...params, date: format(date, "yyyy-MM-dd"), shift: shift}
            }
        }

        return this.request(url, params)
    }

    /**
     * @brief Set Workhours
     * @param {{id?: number, date: string, costCentre: number, startTime: string, endTime: string, shift: 'A'|'I', workHours: number, eveningHours: number, nightHours: number, breakHours: number, waitHours: number, shortTime: number, // Pekkanen sickHours: number, vacationHours: number, holidayHours: number, paternityLeaveHours: number, dailyAllowance: boolean, route: string}} hours workhours row
     * @returns {Promise}
     */
    static setWorkHours(workhours, del) {
        const url = "/setworkhours.php"

        return this.request(url, { workhours: encodeURIComponent(JSON.stringify(workhours)), del: del || false })
    }

    /**
     * @brief "Get areas and subareas"
     * @param {string?} search
     * @param {number?} id
     * @param {string?} extent
     * @param {Boolean?} sub
     * @param {number?} client
     * @returns
     */
    static getAreas({ search = null, id = null, extent = null, sub = null, client = null } = {}) {
        const url = "/getareas.php"
        return this.request(url, { id: id, extent: extent, search: search, sub: sub, client: client })
    }

    /**
     * @brief "Insert or update area or subarea"
     * @param {Object} areas 
     * @param {Boolean || null} sub 
     * @returns
     */
    static setArea(areas, sub = null) {

        const url = "/setarea.php"

        return this.request(url, { areas: encodeURIComponent(JSON.stringify(areas)), sub })
    }

    /**
     * 
     * @param {{start: string, end: string, vehicles: string[], areas: string[], users: number[]}} filters 
     * @param {number[]} rows
     */
    static getClientReport(filters = null, rows = null) {
        const url = "/getclientreports.php";
        const params = { filters: JSON.stringify(filters), rows: JSON.stringify(rows) };

        return this.request(url, params, "post");
    }

    /**
     * 
     * @param {{start: string, end: string, vehicles: number[], clients: number[], jobs?: number[], 
     * talenom?: { billed: boolean, billMode: 'separate'|'group-cars', addWaybillNumber: boolean, onlyWaybillNumber: boolean, addDate: boolean }}} filters 
     * 
     * @return {Promise<GetInvoiceResponse>}
     */
    static getInvoice(filters) {
        const url = "/getinvoice.php";
        const params = { filters: encodeURIComponent(JSON.stringify(filters)) };

        return this.request(url, params);
    }

    /**
     * @brief Creates a Talenom invoice
     * @param {{start: string, end: string, vehicles: number[], clients: number[], jobs?: number[], 
     * talenom?: { billed: boolean, billMode: 'separate'|'group-cars', addWaybillNumber: boolean, onlyWaybillNumber: boolean, addDate: boolean }}} filters 
     * 
     * @return {Promise<CreateTalenomInvoiceResponse>}
     */
    static createTalenomInvoice(filters) {
        // Request uses regex (set|get) to define if it's GET or POST request
        // need to force to GET in this case
        const url = "/createinvoice.php";
        const params = { filters: encodeURIComponent(JSON.stringify(filters)), format: "talenom" };

        return this.request(url, params, "get");
    }

    /**
     * 
     * @param {'clients'|'products'} type 
     * @param {*} file 
     * @returns 
     */
    static setTalenomData(type, file) {

        const url = "/settalenomdata.php";
        const params = { type: type, file: file };

        return this.request(url, params);
    }

    /**
     * 
     * @param {'clients'|'products'} type 
     * @returns 
     */
    static getTalenomData(type) {
        const url = "/gettalenomdata.php";
        const params = { type: type };

        return this.request(url, params);
    }

    /**
    * @brief Fetch servicehistory
    * @returns {Promise} Returns a Promise with plans
    */

    static getServiceHistory({ id = null, method= null, extent = null } = {}) {
        const url = `/getservicehistory.php`
        return this.request(url, { id: id, method: method, extent: extent })
    }

    static getServiceHistoryV2({ timeFrame = null, vehicle = null, method = null } = {}) {
        let url = ""
        url = `/getservicehistory.php`
        let start, end, vehicleId, searchmethod= null
        
        // if (timeFrame) {
        //     console.log(timeFrame.start)
        //     console.log(timeFrame.end)
        //     start = timeFrame.start
        //     end = timeFrame.end
        // }
        if (vehicle) {
            console.log("getServiceHistoryV2, vehicle")
            console.log(vehicle)
            vehicleId = vehicle
        }
        if (method) {
            console.log("getServiceHistoryV2, method")
            console.log(method)
            searchmethod = method
            //url = `/getfutursoft.php`
        }



        if (timeFrame) {
            start =  format(timeFrame.start, "yyyy-MM-dd")
            end = format(timeFrame.end, "yyyy-MM-dd")
            console.log(start)
            console.log(end)
        }

        return this.request(url, { start: start, end: end, vehicleId: vehicleId, method: searchmethod })
    }

    static getServiceHistoryV3({uuid = null, method = null, extent = null} = {}) {
        const url = `/getservicehistory.php`
        let getuuId = null;
        let searchmethod= null;

        if (uuid) {
            console.log("getServiceHistoryV3, uuid")
            console.log(uuid)
            getuuId = uuid
        }
        if (method) {
            console.log("getServiceHistoryV2, method")
            console.log(method)
            searchmethod = method
        }
        return this.request(url, { uuid: getuuId, method: searchmethod, extent: extent  })
    }

    /**
 
     */
    static setServiceHistory(servicehistory, del = null) {

        console.log("Tallennettavat tilauksen kentät")
        const myJSON = JSON.stringify(servicehistory)
        console.log(myJSON)

        const url = `/setservicehistory.php`

        let params = { servicehistory: encodeURIComponent(JSON.stringify(servicehistory)) }

        if (del) {
            params.del = del
        }

        return this.request(url, params)
    }

    static getServiceHistoryRowById({ id = null } = {}) {
        const url = `/getservicehistoryrow.php`
        return this.request(url, { id: id })
    }

    static getServiceHistoryRowsByUuId(uuid) {
        const url = "/getservicehistoryrow.php"
        return this.request(url, { uuid: uuid })
    }

    static setServiceHistoryRow(servicehistoryrow, del = null, deleteall = null, merge = null) {

        console.log("Huoltorivi on:");
        const myJSON = JSON.stringify(servicehistoryrow)
        console.log(myJSON)

        const url = `/setservicehistoryrow.php`
        let params = { servicehistoryrow: encodeURIComponent(JSON.stringify(servicehistoryrow)) }
        if (del) {
            params.del = del
        }
        else if (deleteall) {
            params.deleteall = deleteall
        }
        else if (merge) {
            params.merge = merge
        }
         
        return this.request(url, params)
    }
    
    static sendAutofutur(workorder, del = null) {
        console.log("Autofutur data:");
        const myJSON = JSON.stringify(workorder)
        console.log(myJSON)

        const url = `/setfutursoft.php`
        let params = {workorder: encodeURIComponent(JSON.stringify(workorder)) }

        if (del) {
            params.del = del
        }
        console.log("sendAutofutur params: ",params)
        return this.request(url, params)
    }

    static loadAutofutur(licenseNumbers = null, orders = null, method = null) {
        console.log("Autofutur loading data:");
        console.log(method)
        const myJSON = JSON.stringify(orders)
        console.log(myJSON)
        const myJSON2 = JSON.stringify(licenseNumbers)
        console.log(myJSON2)
   
        const url = `/getfutursoft.php`
        let searchmethod = method
        let params = {vehicles: encodeURIComponent(JSON.stringify(licenseNumbers)), workorders: encodeURIComponent(JSON.stringify(orders)) , method: searchmethod }
        return this.request(url, params)
    }

    static getExternalServices({ id = null } = {}) {        
        const url = `/getexternalservice.php`
        return this.request(url, { id: id })
    }

    static setExternalService(externalservice, del = null) {
        console.log("Ulkopuolinen huoltaja on:");
        const myJSON = JSON.stringify(externalservice)
        console.log(myJSON)

        let params = { externalservice: encodeURIComponent(JSON.stringify(externalservice)) }
        if (del) {
            params.del = del
        }

        const url = `/setexternalservice.php`
        return this.request(url, params)
    }
    
    /**
     * @brief Gets fuel and kilometres for the vehicle
     * @param {number} vehicle 
     * @param {string} date 
     * @param {'A'|'I'} shift 
     * @returns {Promise<GetFuelResponse>} response
     */
    static getFuel (vehicle, date, shift) {
        const url = "/getfuel.php";
        const params = { 
            vehicle: vehicle,
            date: date,
            shift: shift
        };
    
        return this.request(url, params);
    }

    /**
     * 
     * @param {string} from 
     * @param {string} to 
     * @param {number[]} vehicles 
     * @returns {Promise<{status: true, fuel: FuelRow[]}>} response
     */
    static getFuelRange (from, to, vehicles) {
        const url = "/getfuel.php";
        const params = { 
            vehicles: JSON.stringify(vehicles),
            from: from,
            to: to
        };
    
        return this.request(url, params);
    }

    /**
     * @brief Sets fuel and kilometres for the vehicle
     * @param {{vehicle: number, user: number, dateTime: string, shift: 'A'|'I', fuel: number, kilometres: number, id?: number}} data
     */
    static setFuel (data, del = false) {
        const url = "/setfuel.php";
        const params = { fuelData: JSON.stringify(data), del: del ? del : undefined };
    
        return this.request(url, params);
    }

    /**
     * 
     * @param {{from: Date, to: Date, drivers?: number[], vehicles?: number[]}} filters 
     * @return {Promise<DriverReportsResponse>}
     */
    static getDriverReports (filters) {
        const url = "/getdriverreports.php";
        const params = {
            from: format(filters.from, "yyyy-MM-dd"),
            to: format(filters.to, "yyyy-MM-dd")
        }

        return this.request(url, params);
    }

    /**
     * 
     * @param {number[]|null} [vehicles=null] List of vehicles
     * @returns {Promise<GetServiceMonitorResponse>}
     */
    static getServiceMonitor (vehicles = null) {
        const url = "/getservicemonitor.php";
        const params = {
            vehicles: vehicles ? encodeURIComponent(JSON.stringify(vehicles)) : undefined
        }

        return this.request(url, params);
    }

    /**
     * @brief Set/update service monitor
     * 
     * @param {import("./LogitarApiTypes").SetServiceMonitorFormat} monitor 
     * @param {boolean} [approve=false] 
     * @returns {Promise}
     */
    static setServiceMonitor (monitor, approve = false, del = false) {
        const url = "/setservicemonitor.php";
        const params = {
            monitor: encodeURIComponent(JSON.stringify(monitor))
        }
        if(approve)
            params.approve = true;
        if(del)
            params.del = true;

        console.log("Tallennetaan monitor", monitor)
        return this.request(url, params);
    }

    /**
     * @brief Gets fuel prices
     * 
     * @param {FuelType?} fuelType 
     * 
     * @returns {Promise<import("./LogitarApiTypes").GetFuelPriceResponse>}
     */
    static getFuelPrices (fuelType) {
        const url = "/getfuelprices.php";
        const params = {
            fuelType: fuelType || undefined
        }

        return this.request(url, params);
    }

    /**
     * 
     * @param {import("./LogitarApiTypes").FuelPriceRow} data 
     * @param {boolean} del
     * @returns 
     */
    static setFuelPrices (data, del = false) {
        const url = "/setfuelprices.php";
        const params = {
            fuelPrices: encodeURIComponent(JSON.stringify(data))
        }
        if (del) params["del"] = del;

        return this.request(url, params);
    }

    /**
     * 
     * @returns 
     */
    static getPlanningNotes() {
        const url = "/getplanningnotes.php"

        return this.request(url)
    }

    /**
     * 
     * @param {import("./LogitarApiTypes").LogitarPlanningNote} note 
     * @returns 
     */
    static setPlanningNotes(note) {
        const url = "/setplanningnotes.php"

        return this.request(url, {note: JSON.stringify(note)})
    }

    /**
     * 
     * @param {*} driverShifts 
     * @returns 
     */
    static setDriverShifts(driverShifts) {
        const url = "/setdrivershift.php"

        return this.request(url, {driverShifts: encodeURIComponent(JSON.stringify(driverShifts))})
    }

    /**
     * 
     * @param {{
      *  client: number,
      *  from: Date,
      *  to: Date
      * }} filters 
      */
     static getEmissionReports (filters) {
         const url = "/getgeneralreports.php"
 
         if(filters.from) filters.from = format(filters.from, "yyyy-MM-dd");
         if(filters.to) filters.to = format(filters.to, "yyyy-MM-dd");
 
         return this.request(url, {filters: encodeURIComponent(JSON.stringify(filters)), variant: "emissions"}, "post")
     }

    /**
     * 
     * @param {{
     *  from?: Date,
     *  to?: Date,
     *  vehicles?: number[]
     * }} filters 
     */
    static getFuelReports (filters) {
        const url = "/getgeneralreports.php"

        if(filters.from) filters.from = format(filters.from, "yyyy-MM-dd");
        if(filters.to) filters.to = format(filters.to, "yyyy-MM-dd");

        return this.request(url, {filters: encodeURIComponent(JSON.stringify(filters)), variant: "fuel"}, "post")
    }

     static acceptNotification({news, user} = {}) {
        console.log("Tiedotteen kuittaus " + " " + JSON.stringify(news) + " " + user);
        const url = `/setnews.php`
        let params = {news: encodeURIComponent(JSON.stringify(news)), user: user, method: "accept"}
        return this.request(url, params)
    }

    static insertNotification(news) {
        console.log("Notification lisäys " + " " + JSON.stringify(news));
        const url = `/setnews.php`
        let params = {news: encodeURIComponent(JSON.stringify(news)), method: "insertnotification"}
        return this.request(url, params)
    }

    static insertTrainingNotification(training) {
        console.log("Training notification lisäys " + " " + JSON.stringify(training));
        const url = `/settraining.php`
        let params = {training: encodeURIComponent(JSON.stringify(training )), method: "insertnotification"}
        return this.request(url, params)
    }
 
    /**
     * 
     */
    static getBasicTypes() {
        const url = `/getbasictypes.php`
        return this.request(url)
    }

    /**
     * 
     * @param {{*}} basicType 
     * @returns 
     */
    static setBasicTypes(basicType) {
        const url = `/setbasictypes.php`
        return this.request(url, {basicType: encodeURIComponent(JSON.stringify(basicType))})
    }

    /**
     * @brief Gets control data value
     * @param {string} title
     * @param {string} subtitle
     * @returns {Promise} Returns a Promise with control data
     */
    static getControlValues({ title = null, subtitle= null } = {}) {
        const url = `/getcontrolvalues.php`
        return this.request(url, { title: title, subtitle: subtitle })
    }

    //Get holidays
    /**
     * Get Holidays ('Arkipyhä')
     * @param {number} id
     * @param {string} dateSearch
     * @returns 
     */
    static getHolidays({ id = null, dateSearch = null } = {}) {
        const url = `/getholidays.php`
        return this.request(url, { id: id, dateSearch: dateSearch })
    }

    /**
     * 
     * @param {*} holiday 
     * @param {boolean} del 
     * @returns 
     */
    static setHolidays(holiday, del = null) {
        const url = `/setholidays.php`
        return this.request(url, { holiday: encodeURIComponent(JSON.stringify(holiday)), del: del })
    }

    /**
     * 
     * @param {boolean?} nopipes 
     * @returns 
     */
    static getServerStatus(nopipes = undefined) {
        const url = `/getserverstatus.php`
        let params = { nopipes };
        return this.request(url, params)
    }

    /**
     * 
     * @param {{
     *  description: string,
     *  file: string,
     *  line: number,
     *  column: number,
     *  }} error 
     * @returns 
     */
    static frontendError(error) {
        const url = `/frontenderror.php`;
        return this.request(url, { error: encodeURIComponent(JSON.stringify(error))}, "post");
    }

    /**
     * 
     * @param {number[]} users
     * @returns
     */
    static getUserVehicles(users) {
        const url = `/getuservehicles.php`;
        return this.request(url, { users: users });
    }

    /**
     * 
     * @param {number} user
     * @param {string} month
     * @returns
     */
    static getDriverCards(user, month) {
        const url = `/getdrivercards.php`;
        return this.request(url, { user: user, month: month });
    }

    /**
     * 
     * @param {number} vehicle 
     * @param {Date} start 
     * @param {Date} end 
     * @param {boolean?} commit 
     * @returns {Promise<SetGPSTimesResponse>}
     */
    static rerunGPS (vehicle, start, end, commit = false) {
        const url = `/setgpstimes.php`;
        return this.request(url, { vehicle: vehicle, start: format(start, "yyyy-MM-dd"), end: format(end, "yyyy-MM-dd"), commit: commit ? 1 : 0 });
    }
}