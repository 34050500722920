import { Alert, AlertColor, Button, Collapse, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useEffect, useState } from "react";
import LogitarApi from "../api/LogitarApi";

type BannerErrorTypes = "browserOffline" | "apiCallFailed" | "eventDisconnected";
type BannerErrorData = {
    message: string,
    severity: AlertColor,
    variant: "filled" | "standard" | "outlined",
    refreshMessage?: string,
}
const errorTypes: { [T in BannerErrorTypes]: BannerErrorData } = {
    browserOffline: {
        message: "Selaimella ei ole verkkoyhteyttä. Sivu ei toimi ilman verkkoyhteyttä.",
        severity: "error",
        variant: "filled",
    },
    apiCallFailed: {
        message: "Palvelimeen ei saatu yhteyttä. Verkkoyhteyden kanssa saattaa olla ongelmia.",
        severity: "error",
        variant: "filled",
    },
    eventDisconnected: {
        message: "Event palvelimeen ei saatu yhteyttä. Sivulla voi näkyä vanhentunutta tietoa.",
        severity: "warning",
        variant: "standard",
    },
}

export default function ErrorBanner(props: {
    errorTypes: BannerErrorTypes[]
    eventDisconnected?: boolean,
    showRefreshButton?: boolean,
}) {
    const [browserOffline, setBrowserOffline] = useState(false);
    const [apiCallFailed, setApiCallFailed] = useState(false);

    useEffect(() => {
        // useEffect with LogitarApi.fetchFailed as dependency worked, but only after the second request.
        // Periodically checking LogitarApi.fetchFailed instead.
        const fetchFailedInterval = setInterval(() => {
            setApiCallFailed(LogitarApi.fetchFailed);
        }, 5000)
        // Connect offline/online events for browserOffline
        const onOffline = (_event: Event) => {
            setBrowserOffline(true);
        }
        const onOnline = (_event: Event) => {
            setBrowserOffline(false);
        }
        window.addEventListener("offline", onOffline);
        window.addEventListener("online", onOnline);
        return () => {
            window.removeEventListener("offline", onOffline);
            window.removeEventListener("online", onOnline);
            clearInterval(fetchFailedInterval);
        }
    }, [])

    // Set current error (order determines priority)
    let currentError: BannerErrorData | null = null;
    if (props.errorTypes.includes("eventDisconnected") && props.eventDisconnected) {
        currentError = errorTypes.eventDisconnected;
    }
    if (props.errorTypes.includes("apiCallFailed") && apiCallFailed) {
        currentError = errorTypes.apiCallFailed;
    }
    if (props.errorTypes.includes("browserOffline") && browserOffline) {
        currentError = errorTypes.browserOffline;
    }

    return (
        <Collapse in={currentError !== null} orientation="vertical">
            <Alert
                sx={{ alignItems: "center", "& .MuiAlert-action": { p: 0 } }}
                severity={currentError !== null ? currentError.severity : "warning"}
                action={
                    props.showRefreshButton ? (
                        <Button
                            onClick={() => window.location.reload()}
                            startIcon={<Refresh />}
                            variant="outlined"
                            color={currentError !== null ? currentError.severity : "warning"}
                            sx={currentError !== null && currentError.variant === "filled" ? {
                                color: "white", borderColor: "#FFFA", ":hover": { borderColor: "#FFFF", backgroundColor: "#FFF2" }
                            } : undefined}
                        >
                            {currentError && currentError.refreshMessage ? currentError.refreshMessage : "Yritä lataa uudelleen"}
                        </Button>
                    ) : undefined
                }
                variant={currentError ? currentError.variant : "standard"}
            >
                <Typography>
                    {currentError !== null ? currentError.message : "Verkkoyhteyden kanssa saattaa olla ongelmia."}
                </Typography>
            </Alert>
        </Collapse>
    );
}
