import { Download, Info, Print } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridToolbarContainer, gridFilteredSortedRowEntriesSelector, gridFilteredSortedRowIdsSelector, useGridApiContext, useGridApiRef } from "@mui/x-data-grid-pro";
import { format, subDays } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { Fragment, forwardRef, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from "react-to-print";
import LogitarApi, { APIPath } from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import { logoPaths } from "../components/PlanningCarRow";
import Config from "../config/Config";
import { localeText } from "../misc/LocaleText";
import { contentBoxStyle } from "../styles/styles";

const enableTalenomVariants = Config.getBranch() === "konnekuljetus" || Config.getBranch() === "softrain";

// Columns definition
const columnsNormal = [
    { field: "id", headerName: "ID", hidden: true },
    { field: "clientName", headerName: "Asiakaskoodi", width: 128 },
    { field: "billingDate", headerName: "Lask.pvm", type: "date", flex: 1, valueGetter: (params) => params.row.billingDate ? new Date(params.row.billingDate) : null },
    { field: "loadTime", headerName: "Lastattu", type: "date", flex: 1, valueGetter: (params) => params.row.loadTime ? new Date(params.row.loadTime) : null, valueFormatter: (v) => v.value ? format(v.value, "dd.MM.yyyy H:mm") : "" },
    { field: "unloadTime", headerName: "Purettu", type: "date", flex: 1, valueGetter: (params) => params.row.unloadTime ? new Date(params.row.unloadTime) : null, valueFormatter: (v) => v.value ? format(v.value, "dd.MM.yyyy H:mm") : "" },
    { field: "itemName", headerName: "Nimike", flex: 3, minWidth: 200 },
    { field: "cargoMainGroup", headerName: "Pääryhmä", flex: 1 },
    { field: "cargoDivision", headerName: "Jae", flex: 1 },
    { field: "cargoName", headerName: "Tuote", flex: 1 },
    { field: "cargoTypeLabel", headerName: "Tuote kuljettajalle", width: 108 },
    { field: "licenseNumber", headerName: "RekNro", flex: 1, },
    { field: "m3", headerName: "Kuutiot", type: "number", flex: 1, },
    { field: "tons", headerName: "Tonnit", type: "number", flex: 1, },
    { field: "count", headerName: "Kappale", type: "number", flex: 1, },
    { field: "waitTime", headerName: "Odotus", flex: 1, type: "number", },
    { field: "MWh", headerName: "MWh", flex: 1, type: "number", },
    { field: "kilometres", headerName: "Km", type: "number", width: 80 },
    { field: "jobDetails", headerName: "Lisätieto", width: 140 },
]

const columnTalenomVariant = { field: "talenomVariant", headerName: "Laji", flex: 1, 
    valueGetter: (params) => 
        params.row.itemTalenomVariants && 
        params.row.itemTalenomVariants.length > 0 && 
        params.row.itemTalenomVariants.some(v => v.product === params.value) // Need to do this or crashes with a lot of rows for some reason
        ? params.row.itemTalenomVariants.find(v => v.product === params.value).name || params.value : "" };

export const columns = enableTalenomVariants ? columnsNormal.toSpliced(6, 0, columnTalenomVariant) : columnsNormal;

export const totalsColumns = [
    { field: "name", headerName: "", flex: 1, },
    { field: "m3", headerName: "Kuutiot", type: "number", flex: 1, },
    { field: "tons", headerName: "Tonnit", type: "number", flex: 1, },
    { field: "count", headerName: "Kappale", type: "number", flex: 1, },
    { field: "MWh", headerName: "MWh", flex: 1, type: "number", },
    { field: "waitTime", headerName: "Odotus", flex: 1, type: "number", },
    { field: "kilometres", headerName: "Km", type: "number", flex: 1, },
]

// JobRowFields definition
const jobRowFieldsNormal = [
    { key: "billingDate", display: "Lask. Pvm", format: (v) => v == null ? "-" : format(new Date(v), "dd.MM.yyyy") },
    { key: "loadTime", display: "Lastattu", format: (v) => v == null ? "-" : format(new Date(v), "dd.MM. HH:mm") },
    { key: "unloadTime", display: "Purettu", format: (v) => v == null ? "-" : format(new Date(v), "dd.MM. HH:mm") },
    { key: "licenseNumber", display: "RekNro" },
    { key: "clientCompanyName", display: "Asiakas" },
    { key: "cargoTypeLabel", display: "Tuote", wrap: true },
    { key: "m3", display: "Kuutiot", summary: true, format: (v) => v == null ? "" : Number(v).toFixed(3) },
    { key: "tons", display: "Tonnit", summary: true, format: (v) => v == null ? "" : Number(v).toFixed(3) },
    { key: "count", display: "Kappale", summary: true, format: (v) => v == null ? "" : Math.round((Number(v) + Number.EPSILON) * 100) / 100 }, // Rounded to max 2 decimals
    { key: "MWh", display: "MWh", summary: true, format: (v) => v == null ? "" : Number(v).toFixed(2) },
    { key: "waitTime", display: "Odotus", summary: true, format: (v) => v == null ? "" : Number(v).toFixed(0) },
    { key: "kilometres", display: "Km", summary: true, format: (v) => v == null ? "" : Number(v).toFixed(2) },
    { key: "jobDetails", display: "Lisätieto" },
    /*{ key: "type", display: "Laji" },*/
    //{ key: "details", display: "Rahtikirjannumero" },
]

const jobRowFieldTalenomVariant = { key: "talenomVariant", display: "Laji" };

const jobRowFields = enableTalenomVariants ? jobRowFieldsNormal.toSpliced(6, 0, jobRowFieldTalenomVariant) : jobRowFieldsNormal;

export function ExportToolbar(props) {

    const apiRef = useGridApiContext();

    const [fetching, setFetch] = useState(false)

    /** @type {ReturnType<typeof useState<"divided" | "whole">>} */
    const [exportStructure, setExportStructure] = useState("divided")

    const theme = useTheme();

    const fetchReport = () => {
        if (gridFilteredSortedRowEntriesSelector(apiRef).length < 1) {
            enqueueSnackbar("Ei tiedostoon vietäviä rivejä", { variant: "warning" })
            return;
        }

        const formd = new FormData()

        setFetch(true)

        const dates = [];

        gridFilteredSortedRowEntriesSelector(apiRef).forEach((e) => {
            dates.push(new Date(e.model.date))
        })

        const [minDate, maxDate] = [
            format(new Date(Math.min(...dates)), "yyyy-MM-dd"),
            format(new Date(Math.max(...dates)), "yyyy-MM-dd")
        ]

        console.log(minDate, maxDate)

        formd.append("format", "xls")
        formd.append("key", LogitarApi.getCookie("login-token"))
        formd.append("rows", JSON.stringify(gridFilteredSortedRowIdsSelector(apiRef)))
        formd.append("filters", JSON.stringify({ start: minDate, end: maxDate }))
        formd.append("structure", exportStructure)

        fetch(APIPath + `/getclientreports.php`, { method: "POST", body: formd })
            .then((res) => res.blob())
            .then(blob => {

                const newFile = window.URL.createObjectURL(new File([blob], ``, { type: blob.type }))                

                const link = document.createElement('a');
                link.href = newFile;

                link.setAttribute('download', `LogiTar_Asiakas_${minDate}_${maxDate}.xlsx`)
                link.click()


                setFetch(false)
            })
            .catch(() => {
                setFetch(false)
            })
    }

    const onPrint = () => {
        if (gridFilteredSortedRowEntriesSelector(apiRef).length < 1) {
            enqueueSnackbar("Ei tulostettavia rivejä", { variant: "warning" })
            return;
        }

        const dates = [];

        gridFilteredSortedRowEntriesSelector(apiRef).forEach((e) => {
            dates.push(new Date(e.model.date))
        })

        props.onPrint()
    }

    return (
        <GridToolbarContainer>
            <Button onClick={onPrint}>
                <Print></Print>
            </Button>

            <Button
                onClick={() => fetchReport()}
                type="submit"
            >
                {fetching ?
                    <CircularProgress size={"1.2rem"} />
                    : <Download />
                }
            </Button>
            <FormControl>
                <RadioGroup row value={exportStructure} onChange={(e) => setExportStructure(e.target.value)}>
                    <FormControlLabel slotProps={{ typography: { sx: { fontSize: "11pt" } } }} value="divided" control={<Radio size="small" />} label="Yhteysväleittäin" />
                    <FormControlLabel slotProps={{ typography: { sx: { fontSize: "11pt" } } }} value="whole" control={<Radio size="small" />} label="Kuormat rivitietona" />
                </RadioGroup>
            </FormControl>
            {
                props.showColorInfo &&
                <Tooltip title={
                    <>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, py: 0.5 }}>
                            <Box className="row-default-blue" sx={{ width: "1rem", height: "1rem" }} />Keskeneräinen kuorma</Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, py: 0.5 }}>
                            <Box className="row-default-yellow" sx={{ width: "1rem", height: "1rem" }} />Laskutukseen lähettämätön kuorma</Box>
                    </>
                }>
                    <Box sx={{ width: "1.5rem", height: "1.5rem", position: "relative", background: theme.palette.text.primary, borderRadius: "100%" }}>
                        <Info sx={{ position: "absolute" }} className="row-default-yellow" style={{ background: "transparent" }}/>
                        <Info sx={{ position: "absolute" }} className="row-default-blue" style={{ background: "transparent", clipPath: "polygon(0 0, 100% 0, 0% 100%)" }}/>
                    </Box>
                </Tooltip>
            }

        </GridToolbarContainer>
    )

}

/** 
 * @param {{item: object}} props 
 */
const ClientReportsItemRow = (props) => {

    let summaries = {};
    let summaryLabelCol = -1;

    jobRowFields.forEach((ex, ix) => {
        if (ex.summary) {
            summaries[ex.key] = { count: 0, total: 0 };
            if (summaryLabelCol < 0) {
                summaryLabelCol = ix - 1;
            }
        }
    })

    props.item.jobs.forEach((e, i) => {
        jobRowFields.forEach((ex, ix) => {
            if (summaries[ex.key] === null || summaries[ex.key] === undefined) {
                return;
            }
            if (!e[ex.key]) {
                return;
            }
            summaries[ex.key].count++;
            summaries[ex.key].total += Number(e[ex.key]);
        })
    })

    return <Fragment>
        <tr>
            <td colSpan={jobRowFields.length} style={{ paddingBottom: '7px', paddingTop: '10px' }}>
                <Typography variant="h6" sx={{ fontSize: '1em' }}>
                    {props.item.item.itemNumber} - {props.item.item.name}
                </Typography>
            </td>
        </tr>
        <tr>
            {
                jobRowFields.map((e, i) => {
                    return <th key={"job-hdr-" + i} style={{ fontSize: '10pt', textAlign: 'end' }}>
                        {e.display}
                    </th>
                })
            }
        </tr>
        {
            props.item.jobs.map((e, i) => {
                return <tr key={"job-row-" + i}>
                    {
                        jobRowFields.map((ex, ix) => {
                            return <td key={"job-row-" + i + "-job-row-" + ix} style={{ fontSize: '10pt', textAlign: 'end', overflowWrap: ex.wrap ? 'anywhere' : 'normal' }}>
                                {
                                    ex.format ? ex.format(e[ex.key]) : e[ex.key]
                                }
                            </td>
                        })
                    }
                </tr>
            })
        }
        <tr>
            {
                jobRowFields.map((e, i) => {
                    if (i === summaryLabelCol) {
                        return <th key={"job-summary-label-tot-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, wordBreak: 'break-all' }}>
                            Yhteensä:
                        </th>
                    }
                    else if (e.summary) {
                        return <td key={"job-summary-label-tot-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, wordBreak: 'break-all', fontWeight: "bold" }}>
                            {e.format ? e.format(summaries[e.key].total) : summaries[e.key].total}
                        </td>
                    }
                    else {
                        return <th key={"job-summary-label-tot-" + i}></th>
                    }
                })
            }
        </tr>
        <tr>
            {
                jobRowFields.map((e, i) => {
                    if (i === summaryLabelCol) {
                        return <th key={"job-summary-label-avg-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, wordBreak: 'break-all' }}>
                            Keskiarvo:
                        </th>
                    }
                    else if (e.summary) {
                        const v = summaries[e.key].count > 0 ? (summaries[e.key].total / summaries[e.key].count) : 0;
                        return <td key={"job-summary-label-avg-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, wordBreak: 'break-all', fontWeight: "bold" }}>
                            {e.format ? e.format(v) : v}
                        </td>
                    }
                    else {
                        return <th key={"job-summary-label-avg-" + i}></th>
                    }
                })
            }
        </tr>
    </Fragment>
}

/**
 * @typedef {{
 *  report: object[],
 *  show: boolean,
 *  ids: number[],
 *  dateRange: Date[],
 * }} ClientReportPrintAreaProps
 */

/**
 * 
 * @param {ClientReportPrintAreaProps} props 
 */
export const ClientReportPrintArea = forwardRef((props, ref) => {

    // Group report by item
    let report = {};

    // Calculate summaries
    let summaryLabelCol = -1;    

    let sums = {};
    jobRowFields.forEach((ex, ix) => {
        if (ex.summary) {
            sums[ex.key] = { count: 0, total: 0 };
            if (summaryLabelCol < 0) {
                summaryLabelCol = ix - 1;
            }
        }
    })
    
    const [summaries, setSummaries] = useState(sums);

    props.report.forEach(e => {
        if (!props.ids.includes(e.id))
            return;

        if (!report[e.itemNumber]) {
            report[e.itemNumber] = {
                item: {
                    itemNumber: e.itemNumber,
                    name: e.itemName,
                    talenomVariants: e.itemTalenomVariants,
                },
                jobs: []
            }
        }

        report[e.itemNumber].jobs.push({
            id: e.id,
            vehicle: e.vehicle,
            user: e.user,
            date: e.date,
            billingDate: e.billingDate,
            tons: e.tons,
            m3: e.m3,
            MWh: e.MWh,
            count: e.count,
            hours: e.hours,
            clientName: e.clientName,
            clientCompanyName: e.clientCompanyName,
            itemName: e.itemName,
            kilometres: e.kilometres,
            waitTime: e.waitTime,
            jobDetails: e.jobDetails,
            loadTime: e.loadTime,
            unloadTime: e.unloadTime,
            licenseNumber: e.licenseNumber,
            cargoName: e.cargoName,
            cargoMainGroup: e.cargoMainGroup,
            cargoDivision: e.cargoDivision,
            cargoTypeLabel: e.cargoTypeLabel,
            talenomVariant: e.itemTalenomVariants && 
                e.itemTalenomVariants.length > 0 && 
                e.itemTalenomVariants.some(v => v.product === e.talenomVariant) // Need to do this or crashes with a lot of rows for some reason
                ? e.itemTalenomVariants.find(v => v.product === e.talenomVariant).name || e.talenomVariant : e.talenomVariant,
        });
    })

    useEffect(() => {        
        let newSums = {};
        jobRowFields.filter(r => r.summary).forEach(r => newSums[r.key] = { count: 0, total: 0 });
        props.report.forEach(e => {            
            if (!props.ids.includes(e.id))
                return;
            jobRowFields.filter(r => r.summary).forEach((row) => {
                if (e[row.key]) {
                    newSums[row.key].count++;
                    newSums[row.key].total += Number(e[row.key]);
                } else if (row.key === "count") {
                    // For empty count rows, still add 1 to the total
                    newSums[row.key].count++;
                    newSums[row.key].total += 1;
                }
            })
        })        
        setSummaries(newSums);
    }, [props.report, props.ids])

    return <Paper
        sx={{
            display: props.show ? 'block' : 'none',
            position: 'fixed'
        }}

    >
        {
            (Object.keys(report).length > 0) &&

            <Box
                ref={ref}
                sx={{
                    padding: 2,
                    width: '28.0cm',
                    minHeight: '35.0cm',
                    color: 'black'
                }}
            >
                <Box sx={{ position: "relative", pb: 2 }}>
                    <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                        <img width={120} src={logoPaths[Config.getBranch()].path} alt="Logo"></img>
                    </Box>
                    <Typography variant="h5" sx={{ fontSize: '14pt', width: "100%", textAlign: "center" }}>
                        Asiakasraportti
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <table style={{ width: '80%', margin: '0 auto', tableLayout: 'auto' }}>
                        <tbody>
                            <tr>
                                <th>Kuljetukset ajalta</th>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center' }}>{format(props.dateRange[0], "dd.MM.yyyy")} - {format(props.dateRange[1], "dd.MM.yyyy")}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <table style={{ width: '100%', overflow: 'scroll', tableLayout: 'auto' }}>
                        <tbody>
                            {
                                // Rows
                                Object.keys(report).sort().map((e, i) => {
                                    return <ClientReportsItemRow key={"report-item-row-" + i} item={report[e]} />
                                })
                            }
                            <tr>
                                <td colSpan={jobRowFields.length} style={{ padding: 10 }}></td>
                            </tr>
                            <tr>
                                {
                                    jobRowFields.map((e, i) => {
                                        if (e.summary) {
                                            return <th key={"summary-hdr-" + i} style={{ fontSize: '10pt', textAlign: 'end' }}>
                                                {e.display}
                                            </th>;
                                        }
                                        else {
                                            return <td key={"summary-hdr-" + i}></td>;
                                        }
                                    })
                                }
                            </tr>
                            {
                                // Summaries
                                <tr>
                                    {
                                        jobRowFields.map((e, i) => {
                                            if (i === summaryLabelCol) {
                                                return <th key={"summary-label-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0 }}>
                                                    Yhteensä:
                                                </th>
                                            }
                                            else if (e.summary) {
                                                return <td key={"summary-val-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, fontWeight: "bold" }}>
                                                    {e.format ? e.format(summaries[e.key].total) : summaries[e.key].total}
                                                </td>
                                            }
                                            else {
                                                return <th key={"summary-label-" + i}></th>
                                            }
                                        })
                                    }
                                </tr>
                            }
                            {
                                <tr>
                                    {
                                        jobRowFields.map((e, i) => {
                                            if (i === summaryLabelCol) {
                                                return <th key={"summary-label-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0 }}>
                                                    Keskiarvo:
                                                </th>
                                            }
                                            else if (e.summary) {
                                                const v = summaries[e.key].count > 0 ? (summaries[e.key].total / summaries[e.key].count) : 0;
                                                return <td key={"summary-val-" + i} style={{ fontSize: '10pt', textAlign: 'end', padding: 0, fontWeight: "bold" }}>
                                                    {e.format ? e.format(v) : v}
                                                </td>
                                            }
                                            else {
                                                return <th key={"summary-label-" + i}></th>
                                            }
                                        })
                                    }
                                </tr>
                            }
                        </tbody>
                    </table>
                </Box>
            </Box>
        }
    </Paper>
})



/**
 * @typedef {{id: string, label: string, children?: ClientReportsSelect[]}} ClientReportsSelect
 */
export default function ClientReportGrid() {

    const [report, setReport] = useState([]);

    const printArea = useRef();

    const apiRef = useGridApiRef();

    const [printTriggered, setPrintTriggered] = useState(false);
    const [reportIds, setReportIds] = useState([]);

    const handlePrint = useReactToPrint({
        content: () => printArea.current,
        onAfterPrint: () => { setPrintTriggered(false) }
    })

    useEffect(() => {
        if (printTriggered) {            
            handlePrint();
        }
    }, [printTriggered]);

    const [fetching, setFetching] = useState(true);

    const [dateRange, setDateRange] = useState([subDays(new Date, 14), new Date()]);

    const [totals, setTotals] = useState({});

    useEffect(() => {

        setFetching(() => true)

        console.log({
            start: format(dateRange[0], "yyyy-MM-dd"),
            end: format(dateRange[1], "yyyy-MM-dd"),
        })

        LogitarApi.getClientReport({
            start: format(dateRange[0], "yyyy-MM-dd"),
            end: format(dateRange[1], "yyyy-MM-dd"),
        }).then((r) => {
            console.log(r)
            setReport(r.report.filter(r => r.loadTime !== null));
            apiRef.current.autosizeColumns({ columns: ["itemName"], includeHeaders: true, includeOutliers: true });
            setFetching(() => false)
        })

    }, [dateRange])

    return (
        <>
            <Box sx={{ ...contentBoxStyle, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} className={"report-grid"}>
                <Box sx={{ maxWidth: 500 }}>
                    <DateRangeSelect
                        value={dateRange}
                        onChange={(v) => setDateRange(v)}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100% - 56px)" }}>
                    <DataGridPro
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    id: false
                                },
                            },
                        }}
                        slots={{
                            toolbar: ExportToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                onPrint: () => {                                     
                                    setPrintTriggered(true) },
                                showColorInfo: report.some(r => r.state < 4 || r.checkState < 3),
                            }
                        }}
                        unstable_headerFilters
                        columns={columns}
                        rows={report}
                        loading={fetching}
                        cursor="pointer"
                        density="compact"
                        localeText={localeText}
                        hideFooter
                        sx={{ flexGrow: 1 }}
                        onStateChange={() => {
                            setTotals(calculateReportTotals(gridFilteredSortedRowEntriesSelector(apiRef)))
                            setReportIds(gridFilteredSortedRowIdsSelector(apiRef))
                        }}
                        apiRef={apiRef}
                        getRowClassName={(params) => {
                            if (params.row.state < 4) {
                                return "row-default-blue";
                            }
                            else if (params.row.checkState < 3) {
                                return "row-default-yellow";
                            }
                            return "";
                        }}
                    />
                    <DataGridPro
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    id: false
                                },
                            },
                        }}
                        slots={{
                            toolbar: () => null,
                            // columnHeaders: () => null,
                            footerRowCount: () => FooterCustomTotalRows(gridFilteredSortedRowEntriesSelector(apiRef).length),
                        }}
                        columns={totalsColumns}
                        rows={[{
                            id: report.length,
                            field: "totals",
                            name: "Yhteensä",
                            m3: totals.m3,
                            tons: totals.tons,
                            count: totals.count,
                            waitTime: totals.waitTime,
                            MWh: totals.MWh,
                            kilometres: totals.kilometres,
                        }, {
                            id: report.length + 1,
                            field: "avgs",
                            name: "Keskiarvo",
                            m3: totals.avgM3,
                            tons: totals.avgTons,
                            count: totals.avgCount,
                            waitTime: totals.avgWaitTime,
                            MWh: totals.avgMWh,
                            kilometres: totals.avgKilometres,
                        }
                        ]}
                        loading={fetching}
                        cursor="pointer"
                        density="compact"
                        localeText={localeText}
                        getRowClassName={(params) => `report-${params.row.field}`}
                        style={{ maxHeight: "164.5px", borderTop: "1px solid gray" }}
                    />
                </Box>
            </Box>
            <ClientReportPrintArea
                report={report}
                ref={printArea}
                show={printTriggered}
                ids={reportIds}
                dateRange={dateRange}
            />
        </>

    )
}

export function calculateReportTotals(report) {
    if (report[0] && report[0].model) report = report.map(row => row.model);
    const newTotals = { m3: 0, tons: 0, count: 0, waitTime: 0, MWh: 0, kilometres: 0, avgM3: 0, avgTons: 0, avgCount: 0, avgWaitTime: 0, avgMWh: 0, avgKilometres: 0 };
    const newAmounts = { m3: 0, tons: 0, count: 0, waitTime: 0, MWh: 0, kilometres: 0 };
    report.forEach(val => {
        if (val.m3 != null) {
            newTotals.m3 += Number(val.m3);
            newAmounts.m3 += 1;
        }
        if (val.tons != null) {
            newTotals.tons += Number(val.tons);
            newAmounts.tons += 1;
        }
        if (val.count != null) {
            newTotals.count += Number(val.count);
        } else newTotals.count += 1; // Count every empty row as 1
        newAmounts.count += 1;
        if (val.waitTime != null) {
            newTotals.waitTime += Number(val.waitTime);
            newAmounts.waitTime += 1;
        }
        if (val.MWh != null) {
            newTotals.MWh += Number(val.MWh);
            newAmounts.MWh += 1;
        }
        if (val.kilometres != null) {
            newTotals.kilometres += Number(val.kilometres);
            newAmounts.kilometres += 1;
        }
    })
    // m3
    newTotals.avgM3 = newAmounts.m3 > 0 ? newTotals.m3 / newAmounts.m3 : 0;
    newTotals.m3 = newTotals.m3.toFixed(3);
    newTotals.avgM3 = newTotals.avgM3.toFixed(3);
    // tons
    newTotals.avgTons = newAmounts.tons > 0 ? newTotals.tons / newAmounts.tons : 0;
    newTotals.tons = newTotals.tons.toFixed(3);
    newTotals.avgTons = newTotals.avgTons.toFixed(3);
    // count
    newTotals.avgCount = newAmounts.count > 0 ? newTotals.count / newAmounts.count : 0;
    newTotals.count = newTotals.count.toFixed(2);
    newTotals.avgCount = newTotals.avgCount.toFixed(2);
    // waitTime
    newTotals.avgWaitTime = newAmounts.waitTime > 0 ? newTotals.waitTime / newAmounts.waitTime : 0;
    newTotals.waitTime = newTotals.waitTime.toFixed(0);
    newTotals.avgWaitTime = newTotals.avgWaitTime.toFixed(0);
    // MWh
    newTotals.avgMWh = newAmounts.MWh > 0 ? newTotals.MWh / newAmounts.MWh : 0;
    newTotals.MWh = newTotals.MWh.toFixed(2);
    newTotals.avgMWh = newTotals.avgMWh.toFixed(2);
    // kilometres
    newTotals.avgKilometres = newAmounts.kilometres > 0 ? newTotals.kilometres / newAmounts.kilometres : 0;
    newTotals.kilometres = newTotals.kilometres.toFixed(2);
    newTotals.avgKilometres = newTotals.avgKilometres.toFixed(2);
    return newTotals;
}

export function FooterCustomTotalRows(total) {
    return (
        <Typography sx={{ mx: 2, fontSize: "0.875rem" }}>Total Rows: {total}</Typography> // Minus totals and avg row
    )
}