import { Autocomplete, Box, Button, CircularProgress, Divider, TextField, Tooltip } from "@mui/material";
import { contentBoxStyle } from "../styles/styles";
import { DateRangeSelect } from "../components/DateSelect";
import { useEffect, useState } from "react";
import { format, subDays } from "date-fns";
import LogitarApi from "../api/LogitarApi";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { GPSRerunRow } from "../api/LogitarApiTypes";
import { localeText } from "../misc/LocaleText";
import { formatDuration } from "./JobReports";
import { Save, Search } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import AlertPop from "../components/AlertPop";

const getDiffColor = (diff: number) => {
    // Linear interpolation between red and green, where red is when diff is 3600s and green is when diff is 0s
    const t = Math.min(1, Math.max(0, diff / (1 * 60 * 60 * 1000)));
    const r = Math.floor(255 * t);
    const g = Math.floor(255 * (1 - t));
    return `rgba(${r * 0.7},${g * 0.7},0,0.3)`;
}

const gpsReRunnerColumns: GridColDef[] = [
    { field: 'date', headerName: 'Päivä', width: 100, valueFormatter: (params) => format(new Date(params.value), "dd.MM.yyyy") },
    { field: 'shift', headerName: 'Vuoro', width: 34 },
    { field: 'shortName', headerName: 'Nimike', valueGetter: (params) => (`${params.row.item}:${params.row.itemShortName}`), width: 200 },
    { 
        field: 'loadTime', 
        headerName: 'Lastaus (k)', 
        valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM HH:mm:ss") : '',
        description: 'Kuljettajan syöttämä lastausaika',
        width: 130
    },
    { 
        field: 'unloadTime', 
        headerName: 'Purku (k)', 
        valueFormatter: (params) => params.value ? format(new Date(params.value), "dd.MM HH:mm:ss") : '',
        description: 'Kuljettajan syöttämä purkuaika',
        width: 130
    },
    {
        field: 'gpsLoadStart',
        headerName: 'Lastaus alku',
        renderCell: (params) => (
            <Tooltip title={`Alkup. lastaus alku: ${params.row.prevGpsLoadStart ? format(new Date(params.row.prevGpsLoadStart + "Z"), "dd.MM HH:mm:ss") : "-"}`}>
                <span>{params.value ? format(new Date(params.value + "Z"), "dd.MM HH:mm:ss") : ''}</span>
            </Tooltip>
        ),
        description: 'GPS lastausajan alku',
        width: 130
    },
    {
        field: 'gpsLoadEnd',
        headerName: 'Lastaus loppu',
        renderCell: (params) => (
            <Tooltip title={`Alkup. lastaus loppu: ${params.row.prevGpsLoadEnd ? format(new Date(params.row.prevGpsLoadEnd + "Z"), "dd.MM HH:mm:ss") : "-"}`}>
                <span>{params.value ? format(new Date(params.value + "Z"), "dd.MM HH:mm:ss") : ''}</span>
            </Tooltip>
        ),
        description: 'GPS lastausajan loppu',
        width: 130
    },
    {
        field: 'gpsUnloadStart',
        headerName: 'Purku alku',
        renderCell: (params) => (
            <Tooltip title={`Alkup. purku alku: ${params.row.prevGpsUnloadStart ? format(new Date(params.row.prevGpsUnloadStart + "Z"), "dd.MM HH:mm:ss") : "-"}`}>
                <span>{params.value ? format(new Date(params.value + "Z"), "dd.MM HH:mm:ss") : ''}</span>
            </Tooltip>
        ),
        description: 'GPS purkuajan alku',
        width: 130
    },
    {
        field: 'gpsUnloadEnd',
        headerName: 'Purku loppu',
        renderCell: (params) => (
            <Tooltip title={`Alkup. purku loppu: ${params.row.prevGpsUnloadEnd ? format(new Date(params.row.prevGpsUnloadEnd + "Z"), "dd.MM HH:mm:ss") : "-"}`}>
                <span>{params.value ? format(new Date(params.value + "Z"), "dd.MM HH:mm:ss") : ''}</span>
            </Tooltip>
        ),
        description: 'GPS purkuajan loppu',
        width: 130
    },
    {
        field: 'loadDiff',
        headerName: 'Lastaus ero',
        description: 'Erotus kuljettajan syöttämän lastausajan ja GPS:n lastauksen aloituksen välillä',
        valueGetter: (params) => {
            if (params.row.loadTime && params.row.gpsLoadStart) {
                const loadTime = new Date(params.row.loadTime).getTime();
                const gpsLoadStart = new Date(params.row.gpsLoadStart + "Z").getTime();
                return loadTime - gpsLoadStart;
            }
            return null;
        },
        renderCell: (params) => {
            if(params.value === null) return null;

            return <span
                    style={{
                        backgroundColor: getDiffColor(params.value),
                        padding: '2px'
                    }}
                >
                    {params.value ? formatDuration(Math.abs(params.value)) : ''}
                </span>
        }
    },
    {
        field: 'unloadDiff',
        headerName: 'Purku ero',
        description: 'Erotus kuljettajan syöttämän purkuajan ja GPS:n purun lopetuksen välillä',
        valueGetter: (params) => {
            if (params.row.unloadTime && params.row.gpsUnloadEnd) {
                const unloadTime = new Date(params.row.unloadTime).getTime();
                const gpsUnloadEnd = new Date(params.row.gpsUnloadEnd + "Z").getTime();
                return unloadTime - gpsUnloadEnd;
            }
            return null;
        },
        renderCell: (params) => {
            if(params.value === null) return null;

            return <span
                    style={{
                        backgroundColor: getDiffColor(params.value),
                        padding: '2px'
                    }}
                >
                    {params.value ? formatDuration(Math.abs(params.value)) : ''}
                </span>
        }
    }
]


export default function GPSReRunner() {

    const [dateRange, setDateRange] = useState<[Date, Date]>([subDays(new Date(), 7), new Date()]);
    const [vehicles, setVehicles] = useState<any[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<{id: number, label: string} | null>(null);

    const [jobs, setJobs] = useState<GPSRerunRow[]>([]);

    const [fetchedConfig, setFetchedConfig] = useState<{start: Date, end: Date, vehicle: number} | null>(null);

    const [fetching, setFetching] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);

    const apiRef = useGridApiRef();

    useEffect(() => {
        LogitarApi.getVehicles()
            .then((resp) => setVehicles(resp.vehicles))
            .catch((e) => console.error(e));
    }, []);

    const fetchJobs = () => {
        if (selectedVehicle) {
            setFetching(true);
            setFetchedConfig({ start: dateRange[0], end: dateRange[1], vehicle: selectedVehicle.id });
            LogitarApi.rerunGPS(selectedVehicle.id, dateRange[0], dateRange[1])
                .then((resp) => {
                    setJobs(resp.jobs);
                })
                .catch((e) => console.error(e))
                .finally(() => setFetching(false));
        }
    }

    const commitJobs = () => {
        if(fetchedConfig) {
            setFetching(true);
            LogitarApi.rerunGPS(fetchedConfig.vehicle, fetchedConfig.start, fetchedConfig.end, true)
                .then((resp) => {
                    enqueueSnackbar("Kuormien GPS-aikojen päivitys onnistui", { variant: 'success' });
                })
                .catch((e) =>{
                    console.error(e);
                    enqueueSnackbar("Kuormien GPS-aikojen päivitys epäonnistui", { variant: 'error' });
                })
                .finally(() => setFetching(false));
        }
    }

    const getCommitError = () => {
        if(fetching) 
            return "Haku on kesken";

        if(jobs.length === 0) 
            return "Ei tallennettavia rivejä";

        // Compare fetched config to current config
        if(fetchedConfig && dateRange[0] === fetchedConfig.start && dateRange[1] === fetchedConfig.end && selectedVehicle?.id === fetchedConfig.vehicle) {
            return null;
        }
        else {
            return "Hae uudet tiedot ennen tallentamista";
        }
    }

    return <Box sx={{ ...contentBoxStyle, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mx: 2, mb: 1 }} >
            <Autocomplete 
                options={vehicles.map(v => ({ id: v.id, label: `${v.id}:${v.licenseNumber}` }))}
                getOptionLabel={(option) => option.label}
                value={selectedVehicle}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                onChange={(e, v) => setSelectedVehicle(v)}
                sx={{
                    width: 200,
                    mr: 2
                }}
                renderInput={(params) => <TextField {...params} label="Auto" required />}
            />
            <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
            <DateRangeSelect
                value={dateRange}
                onChange={(v) => { if (v[0] && v[1]) setDateRange(v as [Date, Date]) }}
                labels={{ start: 'Alku', end: 'Loppu' }}
                sx={{ mr: 2 }}
            />
            <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
            <Button
                variant="contained"
                color="primary"
                disabled={!selectedVehicle || fetching}
                onClick={fetchJobs}
            >
                <Search />
                Hae
            </Button>
            {
                fetching &&
                <CircularProgress sx={{ ml: 2 }} />
            }
        </Box>
        <DataGridPro
            slotProps={{
                toolbar: {
                    //onPrint: (ids) => { setReportIds(ids); setPrintTriggered(true) }
                }
            }}
            unstable_headerFilters
            columns={gpsReRunnerColumns}
            rows={jobs}
            loading={false}
            density="compact"
            localeText={localeText}
            hideFooter
            sx={{ flexGrow: 1 }}
            apiRef={apiRef}
            onRowClick={(params, event) => {

            }}
        />
        <Box
            sx={{
                p: 1
            }}
        >
            <Tooltip title={getCommitError()}>
                <span>
                    <Button
                        variant="contained"
                        disabled={getCommitError() !== null}
                        onClick={() => setConfirmOpen(true)}
                    >
                        <Save />
                        Tallenna
                    </Button>
                </span>
            </Tooltip>
        </Box>
        {
            confirmOpen &&
            <AlertPop 
                title="Haluatko varmasti tallentaa uudet GPS lastaus- ja purkuajat?"
                cancellable
                onClose={(accepted) => {
                    setConfirmOpen(false);
                    if(accepted) {
                        commitJobs();
                    }
                }}
            />

        }
    </Box>

}